.grp-form-item {
    --min-height: 58px;
}

.color-btn-wrapper {
    padding-left: 20px;
}
.grp-item-color-item {
    --padding-top: 0px;
    --min-height: 8px;
}

.purple-linear-check-box-gradient-bg {
    --background: linear-gradient(180.32deg, #a62fd2 2.85%, #7b61ff 82.51%);
}
.blue_aqua-linear-check-box-gradient-bg {
    --background: linear-gradient(180.86deg, #5260ff 0.73%, #3dc2ff 99.25%);
}
.teal_pink-linear-check-box-gradient-bg {
    --background: linear-gradient(180.34deg, #036b9b -6.17%, #fe95b4 88.76%);
}
.wine-linear-check-box-gradient-bg {
    --background: linear-gradient(180.16deg, #cb3896 0.14%, #162398 99.86%);
}
.sherbert-linear-check-box-gradient-bg {
    --background: linear-gradient(179.77deg, #f27171 9.03%, #fdc40c 66.98%);
}
.pink_coral-linear-check-box-gradient-bg {
    --background: linear-gradient(180.16deg, #e31c88 0.14%, #3df3ff 99.86%);
}
.marine-linear-check-box-gradient-bg {
    --background: linear-gradient(180.16deg, #57c5aa 0.14%, #134591 85.99%);
}
.lemon_lime-linear-check-box-gradient-bg {
    --background: linear-gradient(182.04deg, #4fc97e 3.47%, #fdc40c 90.07%);
}
.circle-icon-btn {
    width: 50px;
    height: 50px;
    --border-radius: 50%;
}
.circle-icon-btn svg {
    transform: scale(1);
}
.grp-form-btn-wrapper {
    padding: 20px;
}
