.header {
    height: auto;
    --min-height: 64px;
}
.header-border-zero {
    --border-width: 0px;
    --border-style: 'none';
}
.main-alert .alert-wrapper {
    text-align: center;
    border-radius: 13px;
    background: #ffffff;
}
.alert-wrapper .alert-head {
    text-align: center;
}

.alert-wrapper .alert-head h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    color: #000000;
}

.alert-wrapper .alert-message {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.382353px;
    color: #000000;
}
.alert-wrapper .alert-button-group {
    display: flex;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.alert-wrapper .alert-button-group .alert-button {
    flex: 1;
    text-align: center;
    text-transform: capitalize;
}
.alert-button-group.sc-ion-alert-md {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.alert-wrapper .alert-button-group .alert-button-role-cancel {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.alert-button-inner.sc-ion-alert-md {
    justify-content: center;
}

.form-button {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 35px;
    --border-radius: 30px;
}
.margin-top-zero {
    margin-top: 0px !important;
}
.form-title {
    margin-left: 20px;
    font-size: 22px;
    letter-spacing: 0.5px;
    font-style: normal;
    font-weight: bold;
    line-height: 26px;
}
.divider {
    height: 43px;
    --background: #f4f5f8;
}
.segment-wrapper {
    margin-top: 26px;
    padding-left: 20px;
    padding-right: 20px;
}
.custom-ion-tab-bar {
    height: 73px;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.item-content {
    --ion-item-background: #fff1cc;
}
.tnx-item-h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.7px;
}
.tnx-item-h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #8d92a5;
    letter-spacing: 0.7px;
}
.tnx-item-price {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.7px;
}
.tnx-list-divider {
    color: #000000;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.7px;
}
.sliding-options {
    height: 60px;
}

.ion-item-option-btn {
    /* width: 60px; */
    height: 60px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.7px;
}
.tnx-item-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 41px;
    height: 41px;
    border-radius: 50%;
}
.tnx-group-assign-modal .modal-wrapper {
    position: fixed;
    bottom: 0px;
    --height: 70%;
    --background: #ffffff;
    border-radius: 16px 16px 0px 0px;
    z-index: 1000;
}

.group-item-title {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.7px;
}
.group-item-sub-title {
    font-size: 9px;
    margin-top: 4px;
}
.group-item-member-star-icon {
    padding-right: 2px;
    padding-left: 2px;
    margin-top: 4px;
    font-size: 10px;
}
.group-item-member-count {
    font-size: 10px;
    background-color: rgba(57, 128, 255, 0.1);
    width: 40px;
    height: 20px;
    border-radius: 10px;
    margin-right: 4px;
    margin-top: 4px;
    align-items: center;
    text-align: center;
}
.group-item-member-count-icon {
    padding-top: 5px;
    padding-right: 2px;
    padding-left: 2px;
}
.group-item-member-count-text {
    padding-right: 2px;
    padding-left: 2px;
    font-size: 14px;
}
.group-item-member-add {
    font-size: 10px;
    background-color: #1868df;
    width: 56px;
    height: 20px;
    border-radius: 10px;
    margin-right: 4px;
    margin-top: 4px;
    align-items: center;
    text-align: center;
}
.group-item-member-add-icon {
    padding-right: 2px;
    padding-left: 2px;
    padding-top: 5px;
    color: #ffffff;
}
.group-item-member-add-text {
    padding-right: 2px;
    padding-left: 2px;
    font-size: 12px;
    font-weight: 700;
    color: #ffffff;
}
.ion-no-border {
    --border: 0px;
}

.margin-top-zero {
    margin-top: 0px !important;
}
.circle-wrapper {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circle-wrapper img {
    margin-left: 34px;
    margin-right: 34px;
    width: 309px;
}
.circle {
    border-radius: 50%;
    width: 169px;
    height: 169px;
    background: #f4f5f8;
}
.group-modal-empty-message-wrapper {
    padding-left: 58px;
    padding-right: 58px;
    padding-top: 24px;
    text-align: center;
    font-size: 16px;
    color: #222428;
}
.purple-linear-gradient-bg {
    --background: linear-gradient(180.02deg, #a62fd2 8.88%, #7b61ff 56.81%);
}
.blue_aqua-linear-gradient-bg {
    --background: linear-gradient(179.77deg, #5260ff 9.03%, #3dc2ff 52.23%);
}
.teal_pink-linear-gradient-bg {
    --background: linear-gradient(179.77deg, #036b9b 9.03%, #fe95b4 52.36%);
}
.wine-linear-gradient-bg {
    --background: linear-gradient(179.77deg, #cb3896 9.03%, #162398 61.98%);
}
.sherbert-linear-gradient-bg {
    --background: linear-gradient(179.77deg, #f27171 9.03%, #fdc40c 66.98%);
}
.pink_coral-linear-gradient-bg {
    --background: linear-gradient(179.77deg, #e31c88 9.03%, #3df3ff 66.98%);
}
.marine-linear-gradient-bg {
    --background: linear-gradient(179.77deg, #57c5aa 9.03%, #134591 66.98%);
}
.lemon_lime-linear-gradient-bg {
    --background: linear-gradient(179.77deg, #4fc97e 9.03%, #fdc80c 65.77%);
}

.purple-linear-gradient-icon-bg {
    background: linear-gradient(180.02deg, #a62fd2 8.88%, #7b61ff 99.24%);
}

.blue_aqua-linear-gradient-icon-bg {
    background: linear-gradient(179.77deg, #5260ff 9.03%, #3dc2ff 99.24%);
}
.teal_pink-linear-gradient-icon-bg {
    background: linear-gradient(179.77deg, #036b9b 9.03%, #fe95b4 99.24%);
}
.wine-linear-gradient-icon-bg {
    background: linear-gradient(179.77deg, #cb3896 9.03%, #162398 99.24%);
}
.sherbert-linear-gradient-icon-bg {
    background: linear-gradient(179.77deg, #f27171 9.03%, #fdc40c 99.24%);
}
.pink_coral-linear-gradient-icon-bg {
    background: linear-gradient(179.77deg, #e31c88 9.03%, #3df3ff 99.24%);
}
.marine-linear-gradient-icon-bg {
    background: linear-gradient(179.77deg, #57c5aa 9.03%, #134591 99.24%);
}
.lemon_lime-linear-gradient-icon-bg {
    background: linear-gradient(180.86deg, #4fc97e 0.71%, #fdc40c 99.24%);
}

.tnx-icon {
    transform: scale(0.65);
}

.alert-modal .modal-wrapper {
    min-height: 20%;
    --height: auto;
    --background: #ffffff;
    width: 90%;
    border-radius: 13px;
}

.delete-btn-danger {
    color: #dc344a !important;
}

.on-board-sig-up-btn {
    --border-radius: 30px;
}
.grp-list-item {
    --min-height: 70px;
}
.memo-text {
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.7px;
    color: #697286;
}
.select-group-modal .modal-wrapper {
    position: fixed;
    bottom: 0px;
    --height: 70%;
    --background: #ffffff;
    border-radius: 16px 16px 0px 0px;
    z-index: 1000;
}
