.delete-alert-content {
    text-align: center;
    border-bottom: 1px solid gray;
    height: 60%;
}
.delete-alert-content h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.5px;
}
.delete-alert-content p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.382353px;
}
.delete-alert-btn-wrapper {
    display: flex;
    flex-direction: row;
    height: 40%;
}
.delete-alert-btn-wrapper div {
    flex: 1;
    display: flex;
    justify-content: center;
}

.delete-alert-btn-wrapper .btn-cancel-wrapper {
    border-right: 1px solid gray;
    height: auto;
}
.alert-btn {
    --border-width: 0px;
    --background-activated: #ffff;
}
.re-login-content {
    text-align: center;
}
.re-login-content h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.5px;
}
.re-login-btn {
    --border-width: 0px;
    --background-activated: #ffff;
}
.re-login-content-wrapper {
    height: 60%;
}
.re-login-btn-wrapper {
    height: 40%;
}
.account-list-item {
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-left: 15.5px;
    margin-right: 15.5px;
    margin-top: 10px;
    margin-bottom: 10px;
    min-height: 64px;
    display: flex;
}
.account-list-item h1 {
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.7px;
}
.account-list-item h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.7px;
    color: #8d92a5;
}
.account-list-item .add-icon {
    font-size: 30px;
}
.account-list-item ion-label .add-new-title {
    font-size: 22px;
}
.account-list-item .unlink-text {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.382353px;
}
.account-get-bill-magic-pro-btn {
    margin: 15.5px;
}
.unlink-button {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 17px !important;
    color: var(--ion-color-danger) !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.unlink-item {
    border-radius: 10px;
    margin-left: 15.5px;
    margin-right: 15.5px;
    margin-top: 10px;
    margin-bottom: 10px;
    min-height: 64px;
    display: flex;
}

.unlink-item h1 {
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.7px;
}
.unlink-item h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.7px;
    color: #8d92a5;
}
.unlink-item-header {
    margin-top: 20px;
    margin: 15.5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}
.accounts-divider {
    margin-top: 16px;
    margin-bottom: 16px;
    height: 1px !important;
    width: 100%;
    background-color: var(--ion-color-gray2);
}
