.on-board-content {
    display: flex;
    flex-direction: column;
}
.on-board-content .swipeable-wrapper {
    height: 80vh;
}
.on-board-content .swipeable-footer {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
}

.slide-content-wrapper {
    flex: 1;
}

.slide-content {
    display: flex;
    flex-direction: column;
}
.banner-wrapper {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
}
.banner-wrapper img {
    height: 60vh;
}

.slide-2 {
    --background: linear-gradient(
        179.76deg,
        #f27171 0.21%,
        #fdc40c 41.67%,
        #fff5d3 70.42%
    );
}

.on-board-sub-title {
    padding-left: 24.5px;
    padding-right: 24.5px;
    padding-bottom: 30px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.7px;
    color: #697286;
}
.on-board-btn-wrapper {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
}

.on-board-sig-up-btn {
    --border-radius: 30px;
    height: 56px;
}
