.avatar-wrapper {
    padding-top: 32px;
    padding-left: 36px;
    min-height: 95px;
}
.list-header {
    padding-left: 36px;
    padding-top: 0px;
}
.list-title {
    margin-top: 15px;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: 0.6px;
}
.list-title2 {
    margin-top: 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 24px !important;
    line-height: 29px;
    letter-spacing: 1.7px;
}
.list-body {
    padding: 10px;
    padding-top: 25px;
    padding-right: 37px;
}
.list-item {
    height: 60px;
}
.list-item-label {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 1.7px;
    color: #222428;
}
.list-item-input {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 1.7px;
    color: #222428;
}
.list-item-body2 {
    /* padding-top: 29px; */
    padding-left: 16px;
    padding-right: 37px;
}
.list-item2-text {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 1.7px;
}
.btn-wrapper {
    padding-left: 36px;
    padding-right: 37px;
}
.add-tnx-from-btn {
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.delete-wrapper {
    text-align: center;
    /* padding-left: 37px; */
    padding-top: 21px;
}
.modal-header {
    height: 60px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    z-index: 10000;
    background: #ffff;
    padding: 22px;
    text-align: center;
    padding-bottom: 11px;
}
.modal-header-title {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #000000;
}
.close-icon {
    position: absolute;
    right: 10px;
    width: 26px;
    height: 26px;
    color: #697286;
}
.custom-date-picker {
    --padding-start: 0px;
}
.import-expense-title-item {
    --border-style: none;
}
.import-expense-title-item ion-text {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 1.2px;
    text-transform: uppercase;

    color: #222428;
}
.tnx-amount-input-wrapper {
    display: flex;
    flex-direction: row;
}
.tnx-amount-input-revert {
    margin-right: 0px;
    text-align: right;
}
.tnx-amount-input-revert .original {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    letter-spacing: 0.7px;
    color: #b0b4c2;
}
.tnx-amount-input-field {
    flex: 1;
}
.custom-padding-item {
    --inner-padding-end: 0px;
}
.info-wrapper {
    display: flex;
    flex-direction: column;
    background: #f4f5f8;
    height: auto;
    margin-left: 25px;
    margin-right: 25px;
    border-radius: 9px;
    padding-bottom: 10px;
}
.info-wrapper .info-header {
    display: flex;
    padding: 16px;
    flex: 1;
    flex-wrap: wrap;
}
.info-header-title {
    flex: 1;

    color: #222428;
}
.info-header-title ion-text {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: #222428;
    margin-left: 5px;
}

.show-hide ion-text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.7px;
    color: #1868df;
}
.info-wrapper .info-raw {
    display: flex;
    padding: 5px;
}
.info-raw .info-col-left {
    flex: 1;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    align-items: center;
    text-align: right;
    letter-spacing: 1.7px;
    color: #697286;
}
.info-raw .info-col-right {
    flex: 2;
    padding-left: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 1.7px;
    color: #222428;
}
.missing-transaction-wrapper {
    padding: 20px;
    align-items: center;
    text-align: center;
    background: #fff1cc;
}
.missing-transaction {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.7px;
    color: #222428;
}
.missing-transaction-link {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.7px;
    color: #dc344a;
}
