.screen-swipeable-view {
    overflow-y: hidden;
}

.screen-swipeable-view .react-swipeable-view-container {
    /* height: 100vh !important; */
}

.screen-title h1 {
    margin-top: unset;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: 0.6px;
}
.screen-sub-title p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.7px;
    color: #697286;
}
.screen-bnt-wrapper {
    /* background: #cb3896; */
    width: 100%;
    height: auto;
    position: fixed;
    padding-top: 10px;
    bottom: 0px;
    background: #ffffff;
    padding-bottom: 10px;
}

.screen-list-item {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-left: 15.5px;
    margin-right: 15.5px;
    margin-top: 10px;
    margin-bottom: 10px;
    min-height: 64px;
    display: flex;
}
.screen-list-item h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
}
.screen1-avatar {
    width: 50px;
    height: 50px;
    left: 18px;
    top: calc(50% - 50px / 2 + 0.5px);
    background: #e6e8ef;
    border-radius: 6px;
}
.screen-action-btn {
    margin: 15.5px;
    box-sizing: border-box;
    --border-radius: 30px;
}
.saving-large-number p {
    margin-top: unset;
    margin-bottom: unset;
    padding: 0px !important;
    font-style: normal;
    font-weight: 800;
    font-size: 80px;
    font-size-adjust: 0.78;
    line-height: 60px;
    letter-spacing: 0.6px;
    background: linear-gradient(210.49deg, #cb3896 18.47%, #162398 74.48%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.sc-4-img {
    width: 275px;
}
.sc-6-a-img {
    width: 255px;
}
.sc-4-img-wrapper {
    display: flex;
    justify-content: center;
}
.lastImgWrapper {
    padding-left: 30px;
    padding-right: 18px;
    padding-top: 40px;
}
.sc-6-b-img {
    margin-top: 46px;
    width: 312px;
}
.sc-8-a-img {
    width: 311px !important;
}
.sc-8-b-img {
    margin-top: 46px;
    width: 312px !important;
}
.on-boarding-screen-footer {
    --border-color: #ffffff;
    --border-style: none;
    --border-width: 0px;
}
