.pg-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    padding-bottom: 7px;
    padding-top: 5px;
}
.pg-wrapper div {
    width: 8px;
    height: 8px;
    background: #c4c4c4;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 50%;
    display: inline-block;
}
.active {
    background: #1868df !important;
}
