.skelton-container {
    padding: 20px;
}
.group-created-date-wrapper {
    text-align: center;
    width: 100%;
}
.group-created-date-wrapper h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: 0.6px;
}
.group-created-date-wrapper p {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: #ffffff;
    opacity: 0.66;
}
.group-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.group-icon-ellipse {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56.61px;
    height: 56.61px;
    border-radius: 50%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15));
}
.group-main-divider-wrapper {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    height: auto;
}
.group-main-divider {
    height: 2px;
    border-width: 0;
    background-color: rgba(255, 255, 255, 0.2);
}
.group-divider-wrapper-empty-screen {
    padding-top: 16px;
    padding-left: 39px;
    padding-right: 39px;
    padding-bottom: 10px;
    height: auto;
}
.dark-divider {
    background-color: #e6e8ef;
}
.settled-balance-wrapper {
    display: flex;
    color: #ffffff;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.7px;
    padding-bottom: 20px;
}
.grp-tnx-list-divider {
    height: 43px;
}
.grp-tnx-list-divider-txt {
    font-size: 17px;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.7px;
}
.group-header-title {
    font-style: normal;
    font-weight: bold;
    line-height: 33px;
    letter-spacing: 0.6px;
}
.group-header {
    --background: transparent;
}
.main-card {
    border-radius: 16px;
    padding: 0px;
    min-height: 100px;
    height: auto;
}
.main-card-title {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.7px;
    color: #000000;
}
.main-card-title-right {
    background: saddlebrown;
    text-align: right;
}
.main-card-item {
    --border-width: 0px;
}
.group-main-divider-dark {
    position: absolute;
    height: 2px;
    border-width: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    margin-left: -20px;
    margin-bottom: 10px;
}
.group-main-card-footer {
    display: flex;
    padding-left: 10px;
    padding-right: 0px;
    padding-top: 20px;
    flex-direction: row;
}
.group-main-card-see-all-btn {
    flex: 1;
    --padding-start: 0px;
    --border-width: 0px;
    --background-activated: red;
}
.group-main-card-chevron-icon {
    margin-left: 10px;
    margin-top: 0px;
}
.group-main-card-see-record-payment {
    flex: 1;
    justify-content: end;
}
.group-list-content-wrapper {
    background-color: #ffffff;
    min-height: 60%;
}
.main-card-summary-item-left {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.7px;
}
.main-card-summary-item-right {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    text-align: right;
}
.grp-tnx-right-wrapper {
    display: flex;
    flex-direction: row;
}
.grp-tnx-right-msg {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    letter-spacing: 0.7px;
    color: #8d92a5;
}
.grp-tnx-right-msg-price {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.7px;
    text-align: right;
    margin-top: 0px !important;
}
.grp-tnx-right-icon-wrapper {
    flex: 1;
    padding-top: 10px;
    padding-right: 0px;
    margin-right: -10px;
    opacity: 0.3;
}
.main-card-summary-item-empty {
    font-style: italic;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.7px;
}
.grp-list-ellipse {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.group-list-title {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.7px;
    color: #000000;
}
.group-list-sub-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.7px;
    color: #8d92a5;
}
.circle-btn {
    --border-radius: 7px;
    width: 77px;
    height: 77px;
}
.circle-btn-active {
    opacity: 1;
}

.grp-icon-list-label {
    padding-left: 20px;
}
.grp-icon-color-label {
    margin-top: 8px;
    padding-left: 20px;
}
.grp-icon-list-wrapper {
    padding-top: 8px;
    padding-left: 20px;
    display: flex;
    overflow-x: scroll;
}
.group-form-icon-ellipse {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}
.group-form-icon-ellipse2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    border-radius: 50%;
}
.skelton-circle {
    --border-radius: 50%;
}
.empty-screen-btn-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 30px;
    padding-left: 39px;
    padding-right: 39px;
}
.grp-empty-btn {
    flex: 1;
    --border-radius: 20px;
}
.grp-empty-btn-icon {
    margin-right: 5px;
}
.add-member-btn {
    margin-right: 5px;
}
.copy-link-btn {
    margin-left: 5px;
}
.grp-empty-text-content-wrapper {
    text-align: center;
    padding-left: 57px;
    padding-right: 57px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #222428;
    height: 55vh;
}
.group-avatar-raw {
    justify-content: center;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
}
.single-avatar-btn {
    height: 30px;
    width: 30px;
    border-width: 2px;
    border-color: #ffffff;
    border-style: solid;
    border-radius: 50px;
    margin-left: 8px;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
}
.avatar-title {
    text-align: center;
    font-size: 13px;
    line-height: 0px;
    font-weight: 600;
    opacity: 0.5;
}
.avatar-add {
    --ionicon-stroke-width: 50px;
    background-color: #ffffff;
    margin-left: 5px;
    margin-top: 5px;
}
.group-witch-btn-raw {
    padding-left: 16px;
    padding-right: 16px;
}
.group-witch-btn {
    --border-radius: 20px;
}
.group-switch-btn {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    letter-spacing: 1px;
}
.record-payment-btn {
    --border-radius: 30px;
}
.tnx-list-total {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    letter-spacing: 0.7px;
    color: #222428;
}
.tnx-total-list-header {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;

    letter-spacing: 0.5px;

    /* System Colors/Black */

    color: #000000;
}
.delete-wrapper-settled-form {
    padding: 20px;
}
.summary-card-title {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    text-align: center;
    letter-spacing: 1px;

    color: #8d92a5;

    margin: 4px 0px;
}
.summary-total-amount {
    font-style: normal;
    font-weight: 300 !important;
    font-size: 40px !important;
    line-height: 48px;
    text-align: center;
    letter-spacing: 0.8px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 4px 0px;
}
.add-memeber-fab-button {
  --background: white;
  width: 42px;
  height: 42px;
  z-index: 1000;
}
.add-memeber-image {
    margin-top: 2px;
    width: 42px;
    height: 42px;
}
.add-transaction-fab-button {
    z-index: 1000;
}