/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
    --ion-background-color: #ffffff;

    /** primary **/
    --ion-color-primary: #1868df;
    --ion-color-primary-rgb: 24, 104, 223;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #155cc4;
    --ion-color-primary-tint: #2f77e2;

    --ion-color-secondary: #3dc2ff;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;

    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    --ion-color-success: #2fd36f;
    --ion-color-success-rgb: 47, 211, 111;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29ba62;
    --ion-color-success-tint: #44d77d;

    --ion-color-warning: #fdc40c;
    --ion-color-warning-rgb: 253, 196, 12;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #dfac0b;
    --ion-color-warning-tint: #fdca24;

    --ion-color-black: #000000;
    --ion-color-black-rgb: 0, 0, 0;
    --ion-color-black-contrast: #ffffff;
    --ion-color-black-contrast-rgb: 255, 255, 255;
    --ion-color-black-shade: #000000;
    --ion-color-black-tint: #1a1a1a;

    --ion-color-danger: #ec445a;
    --ion-color-danger-rgb: 236, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d03c4f;
    --ion-color-danger-tint: #ee576b;

    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    --ion-color-light: #ffffff;
    --ion-color-light-rgb: 255, 255, 255;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #e0e0e0;
    --ion-color-light-tint: #ffffff;

    /* magenta */
    --ion-color-magenta: #a62fd2;
    --ion-color-magenta-rgb: 166, 47, 210;
    --ion-color-magenta-contrast: #ffffff;
    --ion-color-magenta-contrast-rgb: 255, 255, 255;
    --ion-color-magenta-shade: #9229b9;
    --ion-color-magenta-tint: #af44d7;

    /* alert */
    --ion-color-alert: #dc344a;
    --ion-color-alert-rgb: 220, 52, 74;
    --ion-color-alert-contrast: #ffffff;
    --ion-color-alert-contrast-rgb: 255, 255, 255;
    --ion-color-alert-shade: #c22e41;
    --ion-color-alert-tint: #e0485c;

    /* purple */
    --ion-color-purple: #a630d3;
    --ion-color-purple-rgb: 166, 48, 211;
    --ion-color-purple-contrast: #ffffff;
    --ion-color-purple-contrast-rgb: 255, 255, 255;
    --ion-color-purple-shade: #922aba;
    --ion-color-purple-tint: #af45d7;

    /* blue_aqua */
    --ion-color-blue_aqua: #5360ff;
    --ion-color-blue_aqua-rgb: 83, 96, 255;
    --ion-color-blue_aqua-contrast: #ffffff;
    --ion-color-blue_aqua-contrast-rgb: 255, 255, 255;
    --ion-color-blue_aqua-shade: #4954e0;
    --ion-color-blue_aqua-tint: #6470ff;

    /* teal_pink */
    --ion-color-teal_pink: #036b9b;
    --ion-color-teal_pink-rgb: 3, 107, 155;
    --ion-color-teal_pink-contrast: #ffffff;
    --ion-color-teal_pink-contrast-rgb: 255, 255, 255;
    --ion-color-teal_pink-shade: #035e88;
    --ion-color-teal_pink-tint: #1c7aa5;

    /* wine */
    --ion-color-wine: #cb3896;
    --ion-color-wine-rgb: 203, 56, 150;
    --ion-color-wine-contrast: #ffffff;
    --ion-color-wine-contrast-rgb: 255, 255, 255;
    --ion-color-wine-shade: #b33184;
    --ion-color-wine-tint: #d04ca1;

    /* sherbert */
    --ion-color-sherbert: #f37171;
    --ion-color-sherbert-rgb: 243, 113, 113;
    --ion-color-sherbert-contrast: #000000;
    --ion-color-sherbert-contrast-rgb: 0, 0, 0;
    --ion-color-sherbert-shade: #d66363;
    --ion-color-sherbert-tint: #f47f7f;

    /* pink_coral */
    --ion-color-pink_coral: #e41d88;
    --ion-color-pink_coral-rgb: 228, 29, 136;
    --ion-color-pink_coral-contrast: #ffffff;
    --ion-color-pink_coral-contrast-rgb: 255, 255, 255;
    --ion-color-pink_coral-shade: #c91a78;
    --ion-color-pink_coral-tint: #e73494;

    /* marine */
    --ion-color-marine: #57c5ab;
    --ion-color-marine-rgb: 87, 197, 171;
    --ion-color-marine-contrast: #000000;
    --ion-color-marine-contrast-rgb: 0, 0, 0;
    --ion-color-marine-shade: #4dad96;
    --ion-color-marine-tint: #68cbb3;

    /* lemon_lime */
    --ion-color-lemon_lime: #4fc97e;
    --ion-color-lemon_lime-rgb: 79, 201, 126;
    --ion-color-lemon_lime-contrast: #000000;
    --ion-color-lemon_lime-contrast-rgb: 0, 0, 0;
    --ion-color-lemon_lime-shade: #46b16f;
    --ion-color-lemon_lime-tint: #61ce8b;

    /* gc green */
    --ion-color-gcgreen: #8dd3c7;
    --ion-color-gcgreen-rgb: 141, 211, 199;
    --ion-color-gcgreen-contrast: #000000;
    --ion-color-gcgreen-contrast-rgb: 0, 0, 0;
    --ion-color-gcgreen-shade: #7cbaaf;
    --ion-color-gcgreen-tint: #98d7cd;

    /* gc yellow */
    --ion-color-gcyellow: #ffffb3;
    --ion-color-gcyellow-rgb: 255, 255, 179;
    --ion-color-gcyellow-contrast: #000000;
    --ion-color-gcyellow-contrast-rgb: 0, 0, 0;
    --ion-color-gcyellow-shade: #e0e09e;
    --ion-color-gcyellow-tint: #ffffbb;

    /* gc purple */
    --ion-color-gcpurple: #bebada;
    --ion-color-gcpurple-rgb: 190, 186, 218;
    --ion-color-gcpurple-contrast: #000000;
    --ion-color-gcpurple-contrast-rgb: 0, 0, 0;
    --ion-color-gcpurple-shade: #a7a4c0;
    --ion-color-gcpurple-tint: #c5c1de;

    /* gc red */
    --ion-color-gcred: #fb8072;
    --ion-color-gcred-rgb: 251, 128, 114;
    --ion-color-gcred-contrast: #000000;
    --ion-color-gcred-contrast-rgb: 0, 0, 0;
    --ion-color-gcred-shade: #dd7164;
    --ion-color-gcred-tint: #fb8d80;

    /* gc blue */
    --ion-color-gcblue: #80b1d3;
    --ion-color-gcblue-rgb: 128, 177, 211;
    --ion-color-gcblue-contrast: #000000;
    --ion-color-gcblue-contrast-rgb: 0, 0, 0;
    --ion-color-gcblue-shade: #719cba;
    --ion-color-gcblue-tint: #8db9d7;

    /* gc orange */
    --ion-color-gcorange: #fdb462;
    --ion-color-gcorange-rgb: 253, 180, 98;
    --ion-color-gcorange-contrast: #000000;
    --ion-color-gcorange-contrast-rgb: 0, 0, 0;
    --ion-color-gcorange-shade: #df9e56;
    --ion-color-gcorange-tint: #fdbc72;

    /* gc success */
    --ion-color-gcsuccess: #b3de69;
    --ion-color-gcsuccess-rgb: 179, 222, 105;
    --ion-color-gcsuccess-contrast: #000000;
    --ion-color-gcsuccess-contrast-rgb: 0, 0, 0;
    --ion-color-gcsuccess-shade: #9ec35c;
    --ion-color-gcsuccess-tint: #bbe178;

    /* gc pink */
    --ion-color-gcpink: #fccde5;
    --ion-color-gcpink-rgb: 252, 205, 229;
    --ion-color-gcpink-contrast: #000000;
    --ion-color-gcpink-contrast-rgb: 0, 0, 0;
    --ion-color-gcpink-shade: #deb4ca;
    --ion-color-gcpink-tint: #fcd2e8;

    /* gc gray */
    --ion-color-gcgray: #d9d9d9;
    --ion-color-gcgray-rgb: 217, 217, 217;
    --ion-color-gcgray-contrast: #000000;
    --ion-color-gcgray-contrast-rgb: 0, 0, 0;
    --ion-color-gcgray-shade: #bfbfbf;
    --ion-color-gcgray-tint: #dddddd;

    /* gc indigo */
    --ion-color-gcindigo: #bc80bd;
    --ion-color-gcindigo-rgb: 188, 128, 189;
    --ion-color-gcindigo-contrast: #000000;
    --ion-color-gcindigo-contrast-rgb: 0, 0, 0;
    --ion-color-gcindigo-shade: #a571a6;
    --ion-color-gcindigo-tint: #c38dc4;

    /* gc light green */
    --ion-color-gclightgreen: #ccebc5;
    --ion-color-gclightgreen-rgb: 204, 235, 197;
    --ion-color-gclightgreen-contrast: #000000;
    --ion-color-gclightgreen-contrast-rgb: 0, 0, 0;
    --ion-color-gclightgreen-shade: #b4cfad;
    --ion-color-gclightgreen-tint: #d1edcb;

    /* gc light yellow */
    --ion-color-gclightyellow: #ffed6f;
    --ion-color-gclightyellow-rgb: 255, 237, 111;
    --ion-color-gclightyellow-contrast: #000000;
    --ion-color-gclightyellow-contrast-rgb: 0, 0, 0;
    --ion-color-gclightyellow-shade: #e0d162;
    --ion-color-gclightyellow-tint: #ffef7d;

    --ion-color-green_one: #02893b;
    --ion-color-green_one-rgb: 2, 137, 59;
    --ion-color-green_one-contrast: #ffffff;
    --ion-color-green_one-contrast-rgb: 255, 255, 255;
    --ion-color-green_one-shade: #027934;
    --ion-color-green_one-tint: #1b954f;

    /* gray2 */
    --ion-color-gray2: #e6e8ef;
    --ion-color-gray2-rgb: 230, 232, 239;
    --ion-color-gray2-contrast: #000000;
    --ion-color-gray2-contrast-rgb: 0, 0, 0;
    --ion-color-gray2-shade: #caccd2;
    --ion-color-gray2-tint: #e9eaf1;
}

.ion-color-gray2 {
    --ion-color-base: var(--ion-color-gray2);
    --ion-color-base-rgb: var(--ion-color-gray2-rgb);
    --ion-color-contrast: var(--ion-color-gray2-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gray2-contrast-rgb);
    --ion-color-shade: var(--ion-color-gray2-shade);
    --ion-color-tint: var(--ion-color-gray2-tint);
}

.ion-color-red {
    --ion-color-base: var(--ion-color-red);
    --ion-color-base-rgb: var(--ion-color-red-rgb);
    --ion-color-contrast: var(--ion-color-red-contrast);
    --ion-color-contrast-rgb: var(--ion-color-red-contrast-rgb);
    --ion-color-shade: var(--ion-color-red-shade);
    --ion-color-tint: var(--ion-color-red-tint);
}

.ion-color-pink {
    --ion-color-base: var(--ion-color-pink);
    --ion-color-base-rgb: var(--ion-color-pink-rgb);
    --ion-color-contrast: var(--ion-color-pink-contrast);
    --ion-color-contrast-rgb: var(--ion-color-pink-contrast-rgb);
    --ion-color-shade: var(--ion-color-pink-shade);
    --ion-color-tint: var(--ion-color-pink-tint);
}

.ion-color-purple {
    --ion-color-base: var(--ion-color-purple);
    --ion-color-base-rgb: var(--ion-color-purple-rgb);
    --ion-color-contrast: var(--ion-color-purple-contrast);
    --ion-color-contrast-rgb: var(--ion-color-purple-contrast-rgb);
    --ion-color-shade: var(--ion-color-purple-shade);
    --ion-color-tint: var(--ion-color-purple-tint);
}

.ion-color-gcgreen {
    --ion-color-base: var(--ion-color-gcgreen);
    --ion-color-base-rgb: var(--ion-color-gcgreen-rgb);
    --ion-color-contrast: var(--ion-color-gcgreen-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gcgreen-contrast-rgb);
    --ion-color-shade: var(--ion-color-gcgreen-shade);
    --ion-color-tint: var(--ion-color-gcgreen-tint);
}

.ion-color-gcyellow {
    --ion-color-base: var(--ion-color-gcyellow);
    --ion-color-base-rgb: var(--ion-color-gcyellow-rgb);
    --ion-color-contrast: var(--ion-color-gcyellow-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gcyellow-contrast-rgb);
    --ion-color-shade: var(--ion-color-gcyellow-shade);
    --ion-color-tint: var(--ion-color-gcyellow-tint);
}

.ion-color-gcpurple {
    --ion-color-base: var(--ion-color-gcpurple);
    --ion-color-base-rgb: var(--ion-color-gcpurple-rgb);
    --ion-color-contrast: var(--ion-color-gcpurple-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gcpurple-contrast-rgb);
    --ion-color-shade: var(--ion-color-gcpurple-shade);
    --ion-color-tint: var(--ion-color-gcpurple-tint);
}

.ion-color-gcred {
    --ion-color-base: var(--ion-color-gcred);
    --ion-color-base-rgb: var(--ion-color-gcred-rgb);
    --ion-color-contrast: var(--ion-color-gcred-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gcred-contrast-rgb);
    --ion-color-shade: var(--ion-color-gcred-shade);
    --ion-color-tint: var(--ion-color-gcred-tint);
}

.ion-color-gcblue {
    --ion-color-base: var(--ion-color-gcblue);
    --ion-color-base-rgb: var(--ion-color-gcblue-rgb);
    --ion-color-contrast: var(--ion-color-gcblue-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gcblue-contrast-rgb);
    --ion-color-shade: var(--ion-color-gcblue-shade);
    --ion-color-tint: var(--ion-color-gcblue-tint);
}

.ion-color-gcorange {
    --ion-color-base: var(--ion-color-gcorange);
    --ion-color-base-rgb: var(--ion-color-gcorange-rgb);
    --ion-color-contrast: var(--ion-color-gcorange-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gcorange-contrast-rgb);
    --ion-color-shade: var(--ion-color-gcorange-shade);
    --ion-color-tint: var(--ion-color-gcorange-tint);
}

.ion-color-gcsuccess {
    --ion-color-base: var(--ion-color-gcsuccess);
    --ion-color-base-rgb: var(--ion-color-gcsuccess-rgb);
    --ion-color-contrast: var(--ion-color-gcsuccess-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gcsuccess-contrast-rgb);
    --ion-color-shade: var(--ion-color-gcsuccess-shade);
    --ion-color-tint: var(--ion-color-gcsuccess-tint);
}

.ion-color-gcpink {
    --ion-color-base: var(--ion-color-gcpink);
    --ion-color-base-rgb: var(--ion-color-gcpink-rgb);
    --ion-color-contrast: var(--ion-color-gcpink-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gcpink-contrast-rgb);
    --ion-color-shade: var(--ion-color-gcpink-shade);
    --ion-color-tint: var(--ion-color-gcpink-tint);
}
.ion-color-gcgray {
    --ion-color-base: var(--ion-color-gcgray);
    --ion-color-base-rgb: var(--ion-color-gcgray-rgb);
    --ion-color-contrast: var(--ion-color-gcgray-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gcgray-contrast-rgb);
    --ion-color-shade: var(--ion-color-gcgray-shade);
    --ion-color-tint: var(--ion-color-gcgray-tint);
}

.ion-color-gcindigo {
    --ion-color-base: var(--ion-color-gcindigo);
    --ion-color-base-rgb: var(--ion-color-gcindigo-rgb);
    --ion-color-contrast: var(--ion-color-gcindigo-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gcindigo-contrast-rgb);
    --ion-color-shade: var(--ion-color-gcindigo-shade);
    --ion-color-tint: var(--ion-color-gcindigo-tint);
}

.ion-color-gclightgreen {
    --ion-color-base: var(--ion-color-gclightgreen);
    --ion-color-base-rgb: var(--ion-color-gclightgreen-rgb);
    --ion-color-contrast: var(--ion-color-gclightgreen-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gclightgreen-contrast-rgb);
    --ion-color-shade: var(--ion-color-gclightgreen-shade);
    --ion-color-tint: var(--ion-color-gclightgreen-tint);
}
.ion-color-gclightyellow {
    --ion-color-base: var(--ion-color-gclightyellow);
    --ion-color-base-rgb: var(--ion-color-gclightyellow-rgb);
    --ion-color-contrast: var(--ion-color-gclightyellow-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gclightyellow-contrast-rgb);
    --ion-color-shade: var(--ion-color-gclightyellow-shade);
    --ion-color-tint: var(--ion-color-gclightyellow-tint);
}
.ion-color-black {
    --ion-color-base: var(--ion-color-black);
    --ion-color-base-rgb: var(--ion-color-black-rgb);
    --ion-color-contrast: var(--ion-color-black-contrast);
    --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
    --ion-color-shade: var(--ion-color-black-shade);
    --ion-color-tint: var(--ion-color-black-tint);
}
.ion-color-magenta {
    --ion-color-base: var(--ion-color-magenta);
    --ion-color-base-rgb: var(--ion-color-magenta-rgb);
    --ion-color-contrast: var(--ion-color-magenta-contrast);
    --ion-color-contrast-rgb: var(--ion-color-magenta-contrast-rgb);
    --ion-color-shade: var(--ion-color-magenta-shade);
    --ion-color-tint: var(--ion-color-magenta-tint);
}
.ion-color-alert {
    --ion-color-base: var(--ion-color-alert);
    --ion-color-base-rgb: var(--ion-color-alert-rgb);
    --ion-color-contrast: var(--ion-color-alert-contrast);
    --ion-color-contrast-rgb: var(--ion-color-alert-contrast-rgb);
    --ion-color-shade: var(--ion-color-alert-shade);
    --ion-color-tint: var(--ion-color-alert-tint);
}

.ion-color-blue_aqua {
    --ion-color-base: var(--ion-color-blue_aqua);
    --ion-color-base-rgb: var(--ion-color-blue_aqua-rgb);
    --ion-color-contrast: var(--ion-color-blue_aqua-contrast);
    --ion-color-contrast-rgb: var(--ion-color-blue_aqua-contrast-rgb);
    --ion-color-shade: var(--ion-color-blue_aqua-shade);
    --ion-color-tint: var(--ion-color-blue_aqua-tint);
}
.ion-color-teal_pink {
    --ion-color-base: var(--ion-color-teal_pink);
    --ion-color-base-rgb: var(--ion-color-teal_pink-rgb);
    --ion-color-contrast: var(--ion-color-teal_pink-contrast);
    --ion-color-contrast-rgb: var(--ion-color-teal_pink-contrast-rgb);
    --ion-color-shade: var(--ion-color-teal_pink-shade);
    --ion-color-tint: var(--ion-color-teal_pink-tint);
}

.ion-color-wine {
    --ion-color-base: var(--ion-color-wine);
    --ion-color-base-rgb: var(--ion-color-wine-rgb);
    --ion-color-contrast: var(--ion-color-wine-contrast);
    --ion-color-contrast-rgb: var(--ion-color-wine-contrast-rgb);
    --ion-color-shade: var(--ion-color-wine-shade);
    --ion-color-tint: var(--ion-color-wine-tint);
}
.ion-color-sherbert {
    --ion-color-base: var(--ion-color-sherbert);
    --ion-color-base-rgb: var(--ion-color-sherbert-rgb);
    --ion-color-contrast: var(--ion-color-sherbert-contrast);
    --ion-color-contrast-rgb: var(--ion-color-sherbert-contrast-rgb);
    --ion-color-shade: var(--ion-color-sherbert-shade);
    --ion-color-tint: var(--ion-color-sherbert-tint);
}
.ion-color-pink_coral {
    --ion-color-base: var(--ion-color-pink_coral);
    --ion-color-base-rgb: var(--ion-color-pink_coral-rgb);
    --ion-color-contrast: var(--ion-color-pink_coral-contrast);
    --ion-color-contrast-rgb: var(--ion-color-pink_coral-contrast-rgb);
    --ion-color-shade: var(--ion-color-pink_coral-shade);
    --ion-color-tint: var(--ion-color-pink_coral-tint);
}

.ion-color-marine {
    --ion-color-base: var(--ion-color-marine);
    --ion-color-base-rgb: var(--ion-color-marine-rgb);
    --ion-color-contrast: var(--ion-color-marine-contrast);
    --ion-color-contrast-rgb: var(--ion-color-marine-contrast-rgb);
    --ion-color-shade: var(--ion-color-marine-shade);
    --ion-color-tint: var(--ion-color-marine-tint);
}
.ion-color-lemon_lime {
    --ion-color-base: var(--ion-color-lemon_lime);
    --ion-color-base-rgb: var(--ion-color-lemon_lime-rgb);
    --ion-color-contrast: var(--ion-color-lemon_lime-contrast);
    --ion-color-contrast-rgb: var(--ion-color-lemon_lime-contrast-rgb);
    --ion-color-shade: var(--ion-color-lemon_lime-shade);
    --ion-color-tint: var(--ion-color-lemon_lime-tint);
}

.ion-color-green_one {
    --ion-color-base: var(--ion-color-green_one);
    --ion-color-base-rgb: var(--ion-color-green_one-rgb);
    --ion-color-contrast: var(--ion-color-green_one-contrast);
    --ion-color-contrast-rgb: var(--ion-color-green_one-contrast-rgb);
    --ion-color-shade: var(--ion-color-green_one-shade);
    --ion-color-tint: var(--ion-color-green_one-tint);
}
