.s-p {
    --height: 64px;

    --border-width: 1px solid;
    --border-image-source: linear-gradient(
        179.77deg,
        #cb3896 9.03%,
        #162398 72.38%
    );
    --color: #ffff;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
}
.color-1 {
    --background: linear-gradient(188.83deg, #f27171 18.88%, #fdc40c 114.69%);
}
.color-2 {
    --background: linear-gradient(180.02deg, #a62fd2 8.88%, #7b61ff 56.81%);
}
.color-3 {
    --background: linear-gradient(183.13deg, #e31c88 2.59%, #3df3ff 141.02%);
}
.color-4 {
    --background: linear-gradient(172.31deg, #4fc97e 16.98%, #fdc40c 126.77%);
}
