.onboard-title-wrapper {
    /* padding-top: 70px; */
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 0.6px;
}
.mt-70{
    padding-top: 70px;
}
.onboard-sub-title-wrapper {
    padding-left: 42px;
    padding-right: 42px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.7px;
    color: #697286;
    text-align: center;
}
.onboard-card-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}
.onboard-card-content-wrapper {
    width: 343px;
    min-height: 123px;
    background: rgba(47, 211, 111, 0.08);
    border-radius: 10px;
    padding-top: 21px;
    padding-left: 23px;
    padding-right: 23px;
    padding-bottom: 29px;
}
.onboard-card-content-wrapper ion-button {
    margin-top: 23px;
    --border-radius: 30px;
}
.onboard-card-content-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.onboard-card-content-body {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.7px;
    color: #697286;
}
.onboard-card-content-body ul {
    list-style-type: none;
    margin: 0;
    padding: 0px;
}

.onboard-card-content-icon-wrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    /* align-items: center;
    justify-content: center; */
}

.onboard-card-content-text-wrapper {
    flex: 3;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.onboard-card-content-title {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
    letter-spacing: 0.7px;
    color: #222428;
}
.onboard-card-content-note {
    margin-top: 11px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.7px;
    color: #697286;
}

.onboard-screen-main-card-wrapper {
    display: flex;
    flex-direction: row;
    min-height: 64px;
}
.onboard-screen-main-card-section-1 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.onboard-screen-main-card-section-2 {
    flex: 3;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    color: #000000;
}
.onboard-screen-main-card-section-3 {
    flex: 1;
}
.onboard-screen-main-card-btn {
    --border-width: 0px;
}
.add-btn-icon {
    margin-left: 5px;
}
.tnx-loading-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* background: rgba(47, 211, 111, 0.08); */
    padding: 20px;
    width: 100%;
    /* border-radius: 10px; */
    height: 150px;
    margin-top: 50px;
}
.tnx-loading-create-group-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: rgba(47, 211, 111, 0.08);
    padding: 20px;
    width: 100%;
    border-radius: 10px;
    height: 150px;
}
.tnx-loading-phone {
    font-size: 36px;
    color: #b0b4c2;
}
.loading-modal .modal-wrapper {
    width: 90%;
    --height: 55%;
    --background: #ffffff;
    border-radius: 16px 16px 16px 16px;
}
.tnx-loading-modal-header {
    color: #3b3b3b;
    text-align: center;
    font-size: 23px;
}
.tnx-loading-main-wrapper {
    padding: 20px;
}
.empty-screen-onboard-sub-section-title {
    padding-left: 39px;
    padding-right: 39px;
    padding-top: 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.7px;
    color: #697286;
}
.empty-screen-info-link {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.7px;
}
.skip-alert {
    --min-width: 75%;
}

.tnx-filter-popover .popover-content {
    --width: 70%;
}
.credit-card-mask {
    color: #697286;
    text-align: end;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1.2px;
}
.credit-card-title {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.7px;
}
.security-icon-wrapper{
    display: flex;
    justify-content: center;
    margin: 32px;
}
.security-icon-wrapper div{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    padding: 20px;
    background: #F4F5F8;
    border-radius: 8px;
    color: #697286;
}
.security-icon-wrapper div ion-icon{
    margin-right: 10px;
    width: 18px;
    height: 18px;
}
.benefits-green-box{
    min-height: 154px;
    background: #EEFCF4;
    border-radius: 10px;
    margin-left: 17px;
    margin-right: 17px;
    padding: 24px 16px 24px 16px;
    color: #697286;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
}
.benefit-pro-btn{
    margin-top: 16px;
    --background: rgba(24, 104, 223, 0.1);
    --color: #1868DF;
    --background-activated	: rgba(24, 104, 223, 0.1);
}
.why-go-pro-green-box{
    display: flex;
    flex-direction: column;
    margin: 27px;
    background: rgba(47, 211, 111, 0.08);
    border-radius: 10px;
    min-height: 300px;
    padding-left: 21px;
    padding-right: 21px;
    padding-top: 21px;
}
.why-go-pro-header{
    display: flex;
}

.why-go-pro-list{
    flex: 2;
}


.why-go-pro-main-icon{
    flex: 1;
    margin-top: 30px;
}
.why-go-pro-list .item{
    display: flex;
    margin: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.7px;
    color: #222428;
}


.why-go-pro-list .item .list-icon{
    display: flex;
    justify-content: center;
    align-items: top;
    padding-top: 15px;
}

.why-go-pro-list .item p{
    padding-left: 10px;
    display: flex;
}
.price-text{
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.7px;
    color: #222428;
}

.upgrade-alert{
    min-height: 75px;
    background-color: #fff1cb;
    display: flex;
    align-items: center;
    padding-left: 22px;
}
.upgrade-alert-message{
    display: flex;
    flex-direction: column;
    padding-left: 18px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #222428;
}
.upgrade-alert-message .reconnect-link{
    font-weight: bold;
}