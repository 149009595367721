.tnx-screen-works-screen-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.tnx-screen-works-screen-wrapper img {
    height: 57vh;
}
.tnx-screen-works-screen-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 6px 16px 6px 10px;
    font-size: 25px;
}
.tnx-screen-works-screen-wrapper .intro {
    padding: 0px 42.5px 0px 42.5px;
}
.tnx-screen-works-screen-wrapper h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 0.6px;
}
.tnx-screen-works-screen-wrapper p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.7px;
    color: #697286;
}
.tnx-screen-works-next-btn {
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 16px;
}
