.ion-chip-list {
    padding: 10px;
}
.ion-custom-list-header {
    --background: #f4f5f8;
    font-size: 17px;
    height: 43px;
    margin: auto;
}
.divider {
    height: 43px;
    --inner-padding-top: 5px;
    --inner-padding-bottom: 5px;
}
